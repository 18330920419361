import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { APP_CONST, GENDER_OPTIONS } from 'src/app/core/constants/app.constant';
import { AccessType } from 'src/app/core/enums/access-type.enum';
import { AgeGroup } from 'src/app/core/enums/age-group.enum';
import { ApiResponse } from 'src/app/core/interfaces/api-response.interface';
import { RangeValuesEnum } from 'src/app/priority/enum/priority-properties.enum';
import { InputType } from '../../enums/input-type.enum';
import { SelectOption } from '../../model/select-option.model';
import { TableButton, TableColumn } from '../../model/table.model';

@Component({
  selector: 'app-condition-table',
  templateUrl: './condition-table.component.html',
  styleUrls: ['./condition-table.component.scss'],
})
export class ConditionComponent extends BaseComponent implements OnInit {
  @Input() columns: TableColumn[] = [
    {
      label: 'Name',
      key: 'name',
    },
  ];
  @Input() title = '';
  @Input() hideSearch = false;
  @Input() buttons: TableButton[] = [];
  @Input() list: any[] = [];
  optionList: any[] = [];
  @Input() totalRecords = 0;
  @Input() hidePagination: boolean = true;
  @Input() accessName = '';
  @Input() accessTypeCode!: AccessType;
  @Input() dataType: string = '';
  @Input() checkUnique = true;
  @Input() count = 1;

  displayColumns: string[] = ['sno'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateList: EventEmitter<any[]> = new EventEmitter();

  genderOptions = GENDER_OPTIONS;
  countriesOptions: SelectOption[] = [];
  priorityPropertiesOptions: SelectOption[] = Object.values(
    RangeValuesEnum
  ).map((data) => ({
    label: data.replace(/_/g, ' '),
    value: data,
  }));
  ageGroupOptions: SelectOption[] = Object.values(AgeGroup).map((data) => ({
    label: data.replace(/_/g, ' '),
    value: data,
  }));
  inputType = InputType;
  searchObs$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.getCountryList();
    const columnKeys = this.columns.map((col) => col.key);
    this.displayColumns.splice(1, 0, ...columnKeys);
    this.displayColumns.push(
      ...[
        'country',
        'gender',
        'ageGroup',
        'range',
        'weightage',
        'coMorbidity',
        'action',
      ]
    );
    this.searchObs$.pipe().subscribe((searchText) => {
      if (!this.hideSearch) {
        this.getList(searchText);
      }
    });
    this.updateTable(this.list);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['list']?.currentValue) {
      this.updateTable(changes['list'].currentValue);
    }

    if (changes['count']?.currentValue) {
      this.count =
        changes['count'].currentValue > 1 ? changes['count'].currentValue : 1;
    }
  }

  async getList(searchText: string): Promise<void> {
    const payload = {
      limit: 10,
      offset: 0,
      take: 10,
      skip: 0,
      where: searchText?.trim()
        ? {
            name: searchText,
          }
        : {},
    };

    try {
      const response: ApiResponse<[any[], number]> =
        await this.httpService.httpRequest<[any[], number]>(
          this.accessName,
          this.getActionString(
            this.accessType,
            'find-all-and-count-by-admin',
            'find-all'
          ),
          payload
        );
      if (response.status) {
        const [list] = response.data ?? [[], 0];
        this.optionList = list.map((data) => ({
          label: `${data.name} (${data.code})`,
          value: data.code,
        }));
      }
    } catch (e) {
      this.appCatchError(e, 'getTestList');
    }
  }

  /**
   * Find Disease
   * @param $searchText
   */
  findData(searchText: string) {
    this.searchObs$.next(searchText);
  }

  async onOptionSelected(event: any): Promise<void> {
    try {
      const isExists = this.list.filter((data) => data.code === event);

      if (isExists.length > 0 && this.checkUnique) return;

      const response: ApiResponse<[any[], number]> =
        await this.httpService.httpRequest<[any[], number]>(
          this.accessName,
          this.getActionString(1700, 'find-one-by-admin', 'find-one'),
          {
            where: event?.trim()
              ? {
                  name: event,
                }
              : {},
          }
        );
      if (response.status) {
        const conditionalList = Array(this.count)
          .fill(null)
          .map((_) => ({
            ...response.data,
            gender: APP_CONST.DEFAULT_GENDER,
            country: APP_CONST.DEFAULT_COUNTRY,
            valueRange: 'ALL_MAX',
            ageGroup: APP_CONST.DEFAULT_AGE_GROUP,
            weightage: 10,
            coMorbidities: null,
          }));
        this.list = [...this.list, ...conditionalList];
        this.updateTable(this.list);
      }
    } catch (e) {
      this.appCatchError(e, 'getTestList');
    }
  }

  async getCountryList(): Promise<void> {
    const countriesList = await this.appService.getCountryList();
    this.countriesOptions = countriesList.map((data) => ({
      label: data[1],
      value: data[1],
    }));
  }

  updateTable(updatedList: any[]) {
    this.dataSource.data = updatedList;
    this.onUpdateList.emit(updatedList);
  }

  onClickAction(type: any, data: number, index: number) {
    if (type === 'delete') {
      this.list.splice(index, 1);
      this.updateTable(this.list);
    }
  }

  onChangeValue() {
    if (!Array.isArray(this.list)) return;

    const updatedList = this.list.map((data) => {
      const coMorbidity = (data['coMorbidities'] ?? []).map((id: number) => ({
        id,
      }));
      return {
        gender: data.gender ?? APP_CONST.DEFAULT_GENDER,
        country: data.country ?? APP_CONST.DEFAULT_COUNTRY,
        ageGroup: data.ageGroup ?? APP_CONST.DEFAULT_AGE_GROUP,
        valueRange: data.valueRange,
        weightage: +data.weightage,
        [this.dataType]: data.id,
        coMorbidity,
      };
    });

    this.onUpdateList.emit(updatedList);
  }
}
