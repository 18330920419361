import { InputType } from 'src/app/shared/enums/input-type.enum';

import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { WindowRefService } from '../../services/window-ref.service';
import { AccessType } from '../../enums/access-type.enum';
import { BaseComponent } from '../base/base.component';
import { ApiResponse } from '../../interfaces/api-response.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent extends BaseComponent implements OnInit {
  @Input() showAdd: boolean = false;
  @Input() title: string = '';
  @Input() showSearch: string = ''; //show search text will appear as placeholder
  @Output() onSelectedValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() accessName:string = '';
  @Input() addAccessCode:number | number [] = [];
  @Input() accessTypeCode!: number | number[];
  @Input() labelKey = '';
  @Input() valueKey = 'name';
  @Input() filterColumn = 'name';
  @Input() autoComplete = true;
  @Input() help = ''
  list: any[] = [];
  optionList: any[] = [];

  searchText = '';
  inputType = InputType;

  constructor(
    private windowService: WindowRefService,
    injector: Injector,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit(): void {}

  goBack() {
    this.windowService.nativeWindow.history.back();
  }

  goToAddPage() {
    this.router.navigate(['./add'], { relativeTo: this.route });
  }

  onChange(searchText: string) {
    this.onChangeValue.emit(searchText);
    if (!this.autoComplete) return;
    this.getList(searchText);
  }

  async onOptionSelected(event: any): Promise<void> {
    this.onSelectedValue.emit(event);
  }

  async getList(searchText: string): Promise<void> {
    if (!this.autoComplete) return;

    const payload = {
      limit: 10,
      offset: 0,
      take: 10,
      skip: 0,
      where: searchText?.trim()
        ? {
            [this.filterColumn]: searchText,
          }
        : {},
    };

    try {
      const response: ApiResponse<[any[], number]> =
        await this.httpService.httpRequest<[any[], number]>(
          this.accessName,
          this.getActionString(
            this.accessTypeCode,
            'find-all-and-count-by-admin',
            'find-all'
          ),
          payload
        );
      if (response.status) {
        this.list = response.data ?? [[], 0];
        this.optionList = this.list[0].map((data: any) => {
          return {
            label: !!this.labelKey
              ? data[this.labelKey]
              : `${data.name} ${data.code ? '('+data.code+')' : ''}`,
            value: data[this.valueKey],
          };
        });
      }
    } catch (e) {
      this.appCatchError(e, 'getPageList');
    }
  }
}
